import { AtLeastOne } from "../../../../packages/types";

type GetSortIndex<T> = (element: T) => number | string;

export const compareAlphabetically = (left: string, right: string) => left.localeCompare(right, ["no-NO"]);

const compare = <T>(left: T, right: T, indexFunction?: GetSortIndex<T>) => {
  const leftIndex = indexFunction ? indexFunction(left) : left;
  const rightIndex = indexFunction ? indexFunction(right) : right;

  if (typeof leftIndex == "number" && typeof rightIndex == "number") return leftIndex - rightIndex;

  if (typeof leftIndex == "string" && typeof rightIndex == "string") {
    return compareAlphabetically(leftIndex, rightIndex);
  }

  throw new Error("Sort index function gives inconsistent index types");
};

/**
 * Alphabetical sorting restricted to Norwegian locale, for consistent sorting on server and client
 */
export const sortAlphabetical = <T extends unknown>(array: T[], indexFunction?: GetSortIndex<T>): T[] =>
  array.slice().sort((left, right) => compare(left, right, indexFunction));

/**
 * Corresponds to lodash's sortBy called with several comparison functions (no equivalent in ramda)
 */
export const sortByMultiple = <T extends unknown>(array: T[], ...indexFunctions: AtLeastOne<GetSortIndex<T>>): T[] =>
  array.slice().sort((left, right) =>
    indexFunctions.slice(1).reduce(
      // If the previous comparison is 0, it means left and right are equal according to the previous
      // comparison function. That's also the only way it'll be falsy, therefore we can
      // use the or operator || to then apply the next comparison function.
      (previous, indexFunction) => previous || compare(left, right, indexFunction),
      compare(left, right, indexFunctions[0]) // Initial comparison
    )
  );
